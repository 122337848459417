<template>
    <div class="container rtl">
        <div class="row">
            <div class="col-3">
                <div class="form-group">
                    <label for="">لە بەرواری</label>
                    <input type="date" @change="getcompany_invoice" v-model="filter.from" class="form-control">
                </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <label for="">تا بەرواری</label>
                    <input type="date" @change="getcompany_invoice" v-model="filter.to" class="form-control">
                </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <label for="">کۆمپانیا</label>
                    <select class="form-control" v-model="filter.company_id"></select>
                </div>
            </div>
            <div class="col-3">
                <label style="color:white">-</label>
                <div class="form-group">
                <div class="btn btn-success" data-toggle="modal" data-target="#addModal">
                    <span>زیاکردنی پسوڵەی کۆمپانیا</span>
                </div>

                </div>
            </div>
        </div>


        <div class="modal fade" id="addModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form @submit.prevent="addcompany_invoice" autocomplete="off">
                        <div class="modal-header">
                            <h5 class="modal-title">زیادکردنی پسوڵە </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-6 my-1">
                                    <label>بەروار</label>
                                    <input v-model="company_invoice.company_invoice_date" class="form-control"
                                        type="date" required>
                                </div>



                                <div class="col-6 my-1">
                                    <label>کۆمپانیا</label>
                                    <div class="form-group">
                                        <select class="form-control" v-model="company_invoice.company_id">
                                            <option v-for="(item, index) in company" :key="index"
                                                :value="item.company_id">
                                                {{item.company_name}} ({{item.store_name}})</option>
                                        </select>
                                    </div>
                                </div>


                                <div class="col-6 my-1">
                                    <label>ژمارەی پسوڵە</label>
                                    <input v-model="company_invoice.company_invoice_number" class="form-control"
                                        required>
                                </div>


                                <div class="col-6 my-1">
                                    <label>مەخزەن</label>
                                    <div class="form-group">
                                        <select class="form-control" v-model="company_invoice.store_id">
                                            <option v-for="(item, index) in stores" :key="index" :value="item.store_id">
                                                {{item.store_name}}</option>
                                        </select>
                                    </div>
                                </div>



                                <div class="col-6 my-1">
                                    <label>جۆری دراو</label>
                                    <div class="form-group">
                                        <select class="form-control" v-model="company_invoice.company_invoice_currency">
                                            <option value="iqd">دینار</option>
                                            <option value="usd">دۆلار</option>
                                        </select>
                                    </div>
                                </div>



                                <div class="col-6 my-1">
                                    <label>جۆری پسوڵە</label>
                                    <div class="form-group">
                                        <select class="form-control" v-model="company_invoice.company_invoice_type">
                                            <option value="naqd">نقد</option>
                                            <option value="qarz">قەرز</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-6 my-1">
                                    <label>بەکارهێنەر</label>
                                    <div class="form-group">
                                        <select class="form-control" v-model="company_invoice.user_id" disabled>
                                            <option :value="this.$store.getters.auth.user.user_id">
                                                {{this.$store.getters.auth.user.user_name}}</option>
                                        </select>
                                    </div>
                                </div>


                                <div class="col-2">
                                    <div class="form-group">
                                        <button type="submit" class="btn btn-success m-2">زیادکردن</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>


        <!-- <div class="row my-3 border-top">
            <hr>
        </div> -->
        <div class="row">
            <div class="col">
                <table class="table table-striped">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col"> بەروار </th>
                            <th scope="col"> ژ.پسوڵە </th>
                            <th scope="col">کۆمپانیا </th>
                            <th scope="col">بەکارهێنەر </th>
                            <th scope="col">دینار </th>
                            <th scope="col"> دۆلار </th>
                            <th scope="col">گۆڕانکاری</th>
                            <th scope="col">سڕینەوە</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in invoice_list" :key="index">
                            <td>{{item.company_invoice_date}}</td>
                            <td>
                                <router-link :to="'company_invoice_items/'+item.company_invoice_id">
                                    {{item.company_invoice_number}}</router-link>
                            </td>
                            <td>{{item.company_name}}</td>
                            <td>{{item.user_name}}</td>
                            <td>{{$service.toIQD(item.iqd)}}</td>
                            <td>{{$service.toUSD(item.usd)}}</td>
                            <td>
                                <button @click="opencompany_invoice(item , 'remove')" type="button"
                                    class="btn btn-danger btn-sm">
                                    سڕینەوە
                                </button>
                            </td>
                            <td>
                                <button @click="opencompany_invoice(item , 'update')" type="button"
                                    class="btn btn-success btn-sm">
                                    گۆڕانکاری
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>


        <!-- Delete Modal -->
        <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">سڕینەوەی پسوڵەی کۆمپانیا</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        دڵنیایت لە سڕینەوەی پسوڵەی کۆمپانیا؟
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">داخستن</button>
                        <button type="button" class="btn btn-danger" @click="deletecompany_invoice">سڕینەوە</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Update Modal -->
        <div class="modal fade" id="updateModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form @submit.prevent="updatecompany_invoice">
                        <div class="modal-header">
                            <h5 class="modal-title">نوێکردنەوەی پسوڵە </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div class="row">

                                <div class="col-6 my-1">
                                    <label>بەروار</label>
                                    <input v-model="selected_company_invoice.company_invoice_date" class="form-control"
                                        type="date" required>
                                </div>



                                <div class="col-6 my-1">
                                    <label>کۆمپانیا</label>
                                    <div class="form-group">
                                        <select class="form-control" v-model="selected_company_invoice.company_id">
                                            <option v-for="(item, index) in company" :key="index"
                                                :value="item.company_id">
                                                {{item.company_name}}</option>
                                        </select>
                                    </div>
                                </div>


                                <div class="col-6 my-1">
                                    <label>ژمارەی پسوڵە</label>
                                    <input v-model="selected_company_invoice.company_invoice_number"
                                        class="form-control" type="number" min="1" required>
                                </div>


                                <div class="col-6 my-1">
                                    <label>مەخزەن</label>
                                    <div class="form-group">
                                        <select class="form-control" disabled
                                            v-model="selected_company_invoice.store_id">
                                            <option v-for="(item, index) in stores" :key="index" :value="item.store_id">
                                                {{item.store_name}}</option>
                                        </select>
                                    </div>
                                </div>



                                <div class="col-6 my-1">
                                    <label>جۆری دراو</label>
                                    <div class="form-group">
                                        <select class="form-control"
                                            v-model="selected_company_invoice.company_invoice_currency">
                                            <option value="iqd">دینار</option>
                                            <option value="usd">دۆلار</option>
                                        </select>
                                    </div>
                                </div>



                                <div class="col-6 my-1">
                                    <label>جۆری پسوڵە</label>
                                    <div class="form-group">
                                        <select class="form-control"
                                            v-model="selected_company_invoice.company_invoice_type">
                                            <option value="naqd">نقد</option>
                                            <option value="qarz">قەرز</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-6 my-1">
                                    <label>بەکارهێنەر</label>
                                    <div class="form-group">
                                        <select class="form-control" v-model="selected_company_invoice.user_id"
                                            disabled>
                                            <option :value="this.$store.getters.auth.user.user_id">
                                                {{this.$store.getters.auth.user.user_name}}</option>
                                        </select>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">داخستن</button>
                            <button type="submit" class="btn btn-success">نوێکردنەوە</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import moment from 'moment'
    export default {
        data() {
            return {
                selected_company_invoice: {},
                company_invoice: {
                    store_id: 0
                },
                invoice_list: [],
                resetable: false,
                msg: {
                    color: '',
                    model: false,
                    text: ''
                },
                filter: {
                    from: moment().format('YYYY-MM-DD'),
                    to: moment().format('YYYY-MM-DD'),
                    company_id: '',
                }
            }
        },
        computed: {
            company_invoices() {
                return this.$store.getters.company_invoice
            },
            company() {
                return this.$store.getters.company
            },
            stores() {
                return this.$store.getters.store
            },
            auth() {
                return this.$store.getters.auth
            },
            users() {
                return this.$store.getters.users
            }
        },
        methods: {
            getcompany_invoice() {
                this.invoice_list = []
                $('#dataTable').DataTable().destroy();
                axios.post('company_invoice/read.php', this.filter)
                    .then(r => {
                        if (r.data.status == 'ok') {
                            this.invoice_list = r.data.data
                            this.$nextTick(() => {
                                $('#dataTable').DataTable();
                            })
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            addcompany_invoice() {
                let params = this.company_invoice
                axios.post('company_invoice/create.php', params)
                    .then(r => {
                        if (r.data.status == 'ok') {
                            if (this.resetable) {
                                this.invoice_list = [];
                            }
                            this.getcompany_invoice()
                            this.msg = {
                                model: true,
                                text: 'پسوڵەی کۆمپانیا زیادکرا',
                                color: 'success'
                            }
                            this.$toast(this.msg.text);
                            $('#addModal').modal('hide')
                        } else {
                            alert(r.data.message);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            deletecompany_invoice() {
                let params = {
                    company_invoice_id: this.selected_company_invoice.company_invoice_id
                }
                axios.post('company_invoice/delete.php', params)
                    .then(r => {
                        if (r.data.status == 'ok') {
                            $('#deleteModal').modal('hide')
                            this.getcompany_invoice()
                            this.msg = {
                                model: true,
                                text: ' پسوڵەی کۆمپانیا سڕایەوە',
                                color: 'danger'
                            }
                            this.$toast(this.msg.text);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            updatecompany_invoice() {
                let params = this.selected_company_invoice
                axios.post('company_invoice/update.php', params)
                    .then(r => {
                        if (r.data.status == 'ok') {
                            this.getcompany_invoice()
                            $('#updateModal').modal('hide')
                            this.msg = {
                                model: true,
                                text: 'پسوڵەی کۆمپانیا نوێکرایەوە',
                                color: 'success'
                            }
                            this.$toast(this.msg.text);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },
            opencompany_invoice(item, type) {
                this.selected_company_invoice = item
                if (type == 'remove') {
                    $('#deleteModal').modal('show')
                } else {
                    $('#updateModal').modal('show')
                }
            }
        },
        mounted() {
            $('#deleteModal').modal('hide')
            $('#updateModal').modal('hide')
            $('#dataTable').DataTable();
            this.getcompany_invoice()
            this.company_invoice.user_id = this.auth.user.user_id
            this.company_invoice.store_id = this.auth.user.store_id
        },
    }
</script>